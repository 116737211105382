import { useEffect, useState } from 'react';

interface RandomNumberProps {
  number: number | null;
  disabled: boolean;
  isGameOver: boolean;
  currScore: number;
  generateNumberHandler: () => void;
  resetGameHandler: () => void;
}
function RandomNumber(props: RandomNumberProps) {
  const { number, generateNumberHandler, resetGameHandler, disabled, isGameOver, currScore } = props;
  const [highScore, setHighScore] = useState<number>(0);
  const bestRecord = localStorage.getItem('highScore');

  useEffect(() => {
    setHighScore(Number(localStorage.getItem('highScore')));
  }, [bestRecord]);

  return (
    <div className='random-numbers-container'>
      <h2>Twenty Numbers Challenge</h2>
      <h6 className='explanation-title'>Put 20 randomly generated numbers between 1-1000 in order without messing up, it's that easy!</h6>
      <h6>Tomer: 🐴</h6>
      <h6>Fisus: 💩</h6>
      <div className='podium-container row'>
        <div className='podium-column col-4'>
          <h4>🥇 19</h4>
          <ul>
            <li>Nitay</li>
            <li>Aviv</li>
          </ul>
        </div>
        <div className='podium-column col-4'>
          <h4>🥈 18</h4>
          <ul>
            <li>Or B.S</li>
          </ul>
        </div>
        <div className='podium-column col-4'>
          <h4>🥉 17</h4>
          <ul>
            <li>Tomer</li>
            <li>Meni</li>
            <li>Niv</li>
          </ul>
        </div>
      </div>

      <div className='generated-number-container'>{number !== null && <h1>{number}</h1>}</div>

      {isGameOver ? (
        <div>{isGameOver && <span className='game-over-text'>GAME OVER !</span>}</div>
      ) : (
        <button disabled={disabled} className={`random-button ${disabled ? 'disabled' : ''}`} onClick={generateNumberHandler}>
          Generate Number
        </button>
      )}
      <button className='random-button' onClick={resetGameHandler}>
        Reset The Game
      </button>
      <div>
        <span className='current-score-text'>Current Score: {currScore} </span>
      </div>
      <div>
        <span className='high-score-text'>High Score: {highScore} </span>
      </div>
      <div>
        <span className='small-text'>*Chance to win: 1 in 13,656 games.</span>
      </div>
    </div>
  );
}

export default RandomNumber;
