import RandomNumber from './RandomNumber';
import Box from './Box';
import { useState } from 'react';

export interface BoxInterface {
  id: number;
  value: number | null;
}

function GameBoard() {
  const [numbers, setNumbers] = useState<BoxInterface[]>(
    Array.from({ length: 20 }, (_, index) => ({
      id: index + 1,
      value: null,
    }))
  );

  const [randomNumber, setRandomNumber] = useState<number | null>(null);
  const [highScore, setHighScore] = useState<number>(0);

  const generateNewNumberDisabled = !numbers.some((num) => num.value === randomNumber);

  const generateRandomNumber = () => {
    let randomNum: number | null = null;

    const isUnique = (num: number) => {
      return !numbers.some((item) => item.value === num);
    };

    while (randomNum === null || !isUnique(randomNum)) {
      randomNum = Math.floor(Math.random() * 1000) + 1;
    }

    setRandomNumber(randomNum);
  };

  const resetGameHandler = () => {
    setNumbers((prevNumbers) => prevNumbers.map((number) => ({ ...number, value: null })));
    setRandomNumber(null);
    setHighScore(0);
  };

  const onSelectHandler = (selectedBox: BoxInterface) => {
    setNumbers((prevNumbers) => {
      const isNumberExists = prevNumbers.some((number) => number.value === randomNumber);
      if (isNumberExists) {
        return prevNumbers;
      }
      const newScore = highScore + 1;
      setHighScore(newScore);
      if (newScore > Number(localStorage.getItem('highScore'))) {
        localStorage.setItem('highScore', newScore.toString());
      }
      return prevNumbers.map((number) => (number.id === selectedBox.id ? { ...number, value: randomNumber } : number));
    });
  };

  const isBoxDisabled = (box: BoxInterface): boolean => {
    if (randomNumber) {
      // Get the box with the maximum value that is less than randomNumber
      const boxWithMaxLesserValue = numbers.filter((b) => b.value !== null && b.value < randomNumber).sort((a, b) => b.value! - a.value!)[0];

      // Get the box with the minimum value that is greater than randomNumber
      const boxWithMinGreaterValue = numbers.filter((b) => b.value !== null && b.value > randomNumber).sort((a, b) => a.value! - b.value!)[0];

      if (boxWithMinGreaterValue && box.id > boxWithMinGreaterValue.id) {
        // Disable the box if its ID is greater than the box with the minimum greater value
        return true;
      }

      if (boxWithMaxLesserValue && box.id < boxWithMaxLesserValue.id) {
        // Disable the box if its ID is less than the box with the maximum lesser value

        return true;
      }

      // Otherwise, the box is not disabled

      return false;
    } else {
      return false;
    }
  };

  const isGameOver = (): boolean => {
    // Check if every box is either filled or would be disabled
    const allBoxesDisabledOrFilled = numbers.every((box) => {
      return box.value !== null || isBoxDisabled(box);
    });

    // If all boxes are either filled or disabled, the game is over
    return allBoxesDisabledOrFilled && generateNewNumberDisabled;
  };

  return (
    <>
      <div className='game-board-container row'>
        <div className='game-board-column game-numbers-column col-3'>
          {numbers.map((number) => (
            <Box key={number.id} number={number} onSelect={onSelectHandler} disabled={isBoxDisabled(number)} />
          ))}
        </div>
        <div className=' game-board-column col-9'>
          <RandomNumber
            generateNumberHandler={generateRandomNumber}
            resetGameHandler={resetGameHandler}
            number={randomNumber}
            disabled={generateNewNumberDisabled}
            isGameOver={isGameOver()}
            currScore={highScore}
          />
        </div>
      </div>
    </>
  );
}

export default GameBoard;
