import { BoxInterface } from './GameBoard';

interface BoxProps {
  number: BoxInterface;
  onSelect: (number: BoxInterface) => void;
  disabled: boolean;
}

function Box(props: BoxProps) {
  const { number, onSelect, disabled } = props;

  return (
    <div
      onClick={disabled || number.value !== null ? undefined : () => onSelect(number)}
      className={`box-container ${number.value !== null || disabled ? 'disabled' : ''}`}
    >
      <span className='box-id-text'>{`${number.id}.  `}</span>
      <span>{`${number.value !== null ? number.value : ''}`}</span>
    </div>
  );
}

export default Box;
