import React, { useEffect } from 'react';
import './index.css';
import GameBoard from './GameBoard';

const App: React.FC = () => {
  useEffect(() => {
    localStorage.setItem('highScore', '0');
  }, []);

  return <GameBoard />;
};

export default App;
